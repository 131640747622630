import { createApp as createVueApp, createSSRApp, defineComponent, resolveComponent, openBlock, createBlock } from 'vue'
import Layout from '@/components/Layout/Layout.vue'
import '@/assets/styles/app.css'

const App = defineComponent({
  render: () => (
    openBlock(),
    createBlock(resolveComponent('router-view'))
  ),
})

export function createApp(router) {
  const app = process.env.NODE_ENV === 'production' ? createSSRApp(App) : createVueApp(App)
  app.use(router)
  app.component('Layout', Layout)

  return app
}
