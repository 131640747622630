import { createWebHistory } from 'vue-router'
import createRouter from '@/router'
import { createApp } from '@/App.js'

const router = createRouter(createWebHistory(process.env.BASE_URL))
const app = createApp(router)

router.isReady().then(() => {
  app.mount('#app')
})
