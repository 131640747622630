/* eslint-disable no-unused-vars */
import { MicrosoftIcon, AmazonWebServicesIcon } from '@/components/BrandIcons'
import { markRaw } from 'vue'

export default markRaw([
  //{
  //  name: 'Services',
  //  menu: {
  //    primary: [
  //      {
  //        name: 'Microsoft 365',
  //        route: '/services/microsoft-365',
  //        description: 'Managed email services for all business sizes.',
  //        icon: MicrosoftIcon,
  //      },
  //      {
  //        name: 'Amazon Web Services',
  //        route: '/services/amazon-web-services',
  //        description: 'Managed cloud infrastructure services.',
  //        icon: AmazonWebServicesIcon,
  //      },
  //    ],
  //    secondary: [
  //      {
  //        name: 'App Development',
  //        route: '/services/app-development',
  //      }
  //    ],
  //  },
  //},
  //{
  //  name: 'Pricing',
  //  route: '/support/pricing',
  //  footer: false,
  //},
  //{
  //  name: 'Support',
  //  footer: [
  //    { name: 'Pricing', route: '/support/pricing' }
  //  ],
  //},
  {
    name: 'Company',
    route: '/company',
    footer: [
      { name: 'About Us', route: '/company/about' },
      { name: 'Contact Us', route: '/company/contact' },
      { name: 'Privacy Policy', route: '/company/privacy' },
    ],
  },
])
