import { createRouter } from 'vue-router'
import Home from '@/views/Home.vue'
import NotFound from '@/views/Errors/NotFound.vue'

const formatTitle = (title) => `${title} | InnoTek Services`

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'InnoTek - Managed IT Services',
    },
  },
  {
    path: '/company',
    name: 'company',
    component: () => import(/* webpackChunkName: "company" */ '@/views/Company/Home.vue'),
    meta: {
      title: formatTitle('Company'),
    },
    children: [
      {
        path: '/company/about',
        name: 'company.about',
        component: () => import(/* webpackChunkName: "company.about" */ '@/views/Company/About.vue'),
        meta: {
          title: formatTitle('About Us'),
        },
      },
      {
        path: '/company/contact',
        name: 'company.contact',
        component: () => import(/* webpackChunkName: "company.contact" */ '@/views/Company/Contact.vue'),
        meta: {
          title: formatTitle('Contact Us'),
        },
      },
      {
        path: '/company/privacy',
        name: 'company.privacy',
        component: () => import(/* webpackChunkName: "company.privacy" */ '@/views/Company/Privacy.vue'),
        meta: {
          title: formatTitle('Privacy Policy'),
        },
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound,
    meta: {
      title: formatTitle('Page Not Found'),
      httpStatus: 404,
    },
  }
]

export default function (history) {

  const router = createRouter({
    history, routes,
    scrollBehavior: (to, from, savedPosition) => savedPosition || { top: 0 },
  })

  router.afterEach((to) => {
    if (typeof document !== 'undefined') {
      document.title = to.meta.title || 'InnoTek Services'
    }
  })

  return router

}
